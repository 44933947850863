import React, {useState} from 'react';
import {Alert, Spinner, Table} from "react-bootstrap";
import './App.css';
import {CSVDownloader, CSVReader} from "react-papaparse";

function App() {

    const [error] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [formatType, setFormatType] = useState('');
    const [erkanntesFormat, setErkanntesFormat] = useState('');
    const [verarbeiteteZeilen, setVerarbeiteteZeilen] = useState(0);
    const [kundennummerNeedsExpansion, setKundennummerNeedsExpansion] = useState(0);
    const [kontoNeedsRecoding, setKontoNeedsRecoding] = useState(0);
    const [showSuccess, setShowSuccess] = useState(false);
    const [processedData, setProcessedData] = useState([]);
    const [mappingTable] = useState(null);
    const [replacedKontonummern, setReplacedKontonummern] = useState([]);

    let verarbeiteteZeilenCounter: number = 0;
    let kundennummerNeedsExpansionCounter: number = 0;
    let kontoNeedsRecodingCounter: number = 0;
    let processedDataDump: any = [];
    let mappingTableVar: { [number: number]: number } = {
        10322: 1410,
        1219: 1410,
        1220: 1410,
        3501: 1410,
        3503: 1410,
        3504: 1410,
        3805: 1576,
        4101: 2110,
        4400: 4971,
        4401: 4972,
        4402: 4973,
        4700: 8737,
        1461: 1591,
        31010: 1410
    }
    let replacedKontonummernVar: any = {}

    const handleOnDrop = (data: any) => {
        setLoading(true)

        let first_iteration = true;
        for (const row of data) {
            if (first_iteration) {
                determineType(row)
                first_iteration = false
            } else {
                probeData(row, true)
            }

        }

        setKundennummerNeedsExpansion(kundennummerNeedsExpansionCounter)
        setKontoNeedsRecoding(kontoNeedsRecodingCounter)
        setProcessedData(processedDataDump)
        setVerarbeiteteZeilen(verarbeiteteZeilenCounter)
        setReplacedKontonummern(replacedKontonummernVar)
        setLoading(false)
        if (error) {
            setShowSuccess(false)
            setShowError(true)
        } else {

            setShowSuccess(true)
            setShowSuccess(true)
        }
    }

    function addToData(row: any) {
        processedDataDump = processedDataDump.concat(row);
    }

    function determineType(row: any) {

        const crefoAuszahlungExport = ['datum', 'konto', 'text', 'kd_nr', 'rg_nr', 'leerspalte', 'betrag', 'op_nr', 'auszugsnummer']

        if (crefoAuszahlungExport.filter(item => row.data.indexOf(item) < 0).length === 0) {
            setFormatType('crefoAuszahlung')
            setErkanntesFormat('crefo Auszahlungsexport')
        }

    }

    const probeData = (row: any, process: boolean = false) => {


        if (row.data[3]) {
            let processesRow = {
                'datum': row.data[0],
                'konto': parseInt(row.data[1]),
                'text': row.data[2],
                'kd_nr': row.data[3],
                'rg_nr': row.data[4],
                'leerspalte': '',
                'betrag': row.data[6],
                'op_no': row.data[7],
                'auszugsnummer': row.data[8]
            }


            if (processesRow.kd_nr.length === 4) {

                processesRow.kd_nr = processesRow.kd_nr + "0"
                kundennummerNeedsExpansionCounter++;
            }

            if (processesRow.kd_nr === "31010") {
                processesRow.kd_nr = "31011"
            }

            processesRow.konto = processKontonummern(processesRow.konto)

            if (process) {

                addToData(processesRow);
            }
            verarbeiteteZeilenCounter++;
        }

    }

    function processKontonummern(kontonummer: number): number {

        for (const mappingTableKey in mappingTableVar) {

            const key = parseInt(mappingTableKey);
            if (key === kontonummer) {

                console.log('FOUND!!!')

                kontoNeedsRecodingCounter++;
                if (replacedKontonummernVar[key]) {
                    replacedKontonummernVar[key]++
                } else {
                    replacedKontonummernVar[key] = 1
                }

                return mappingTableVar[key]
            }
        }

        return kontonummer
    }

    const handleOnError = (err: any, file: any, inputElem: any, reason: any) => {
        console.log(err)
        console.log(file)
        console.log(inputElem)
        console.log(reason)
    }

    const handleOnRemoveFile = (data: any) => {
        setShowSuccess(false)
        setShowError(false)
    }

    return (

        <main className="form-signin">
            <h2 className="mb-4">SL.IS Buchhaltungskonverter <br/>
                <small>- v0.1 ALPHA</small>
            </h2>
            <p className="mb-3">Dies ist der erste Aufschlag für ein Konvertierungsdienst aller Buchuhaltungsthemen der
                SL.IS.
                <br/>
                Langfristig soll hier ein Mechanismus etabliert werden, welcher eigenständig Eingangsformate erkennt und
                notwendige Änderungen selbständig durchführt.
                <br/>
                Auch soll in kommenden Versionen eine Konfiguration durch den Nutzer ermöglicht werden.
            </p>

            <CSVReader onDrop={handleOnDrop} onError={handleOnError} addRemoveButton config={{encoding: 'ISO-8859-3'}}
                       onRemoveFile={handleOnRemoveFile}>
                <span>Eingangsdatei hochladen</span> </CSVReader>

            <Alert show={showSuccess} className="mt-5" variant="success"> <Alert.Heading>Datei wurde
                konvertiert</Alert.Heading>

                <Table striped borderless variant="dark" size="sm">
                    <tr>
                        <th>Erkanntes Format</th>
                        <td>{erkanntesFormat}</td>
                    </tr>
                    <tr>
                        <th>verarbeitete Zeilen</th>
                        <td>{verarbeiteteZeilen}</td>
                    </tr>
                    <tr>
                        <th>Konto - Umschlüsselungen</th>
                        <td>{kontoNeedsRecodingCounter}</td>
                    </tr>
                    <tr>
                        <th>Erweiterte Kundennummern</th>
                        <td>{kundennummerNeedsExpansion}</td>
                    </tr>
                </Table>

                {/*<Table striped bordered hover size="sm">*/} {/*    <thead>*/} {/*    <th>Konto</th>*/} {/*    <th>umgeschlüsselt zu</th>*/} {/*    <th>Anzahl</th>*/} {/*    </thead>*/} {/*    <tbody>*/}

                {/*    </tbody>*/} {/*</Table>*/}

                <CSVDownloader filename={'filename'} config={{delimiter: ";"}} type="button"
                               className="btn btn-primary btn-block" data={() => processedData}> konvertierte
                    Datei herrunterladen </CSVDownloader>

            </Alert>

            <Alert show={showError} variant="danger"> <Alert.Heading>{error}</Alert.Heading>

                {formatType} {mappingTable} {replacedKontonummern} {kontoNeedsRecoding}
            </Alert>

            <Alert show={isLoading} variant="secondary" className="text-center">
                <Spinner animation="border" variant="primary" role="status">
                    <span className="visually-hidden">Loading...</span> </Spinner> </Alert>
        </main>
    );

}


export default App;
